<template>
    <div class="page">
        <div class="hzynav w-1350">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{path: '/gqdj?hzy_id=9'}">外贸营销平台工具</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>供求对接</el-breadcrumb-item> -->
                <!-- <el-breadcrumb-item>供应端</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
        <div class="conter w-1350">
            <div class="text">{{detailsList.name}}</div>
            <!-- <div class="button">
                <img src="../../../assets/images/button.png" alt="">
                <p>发布供求易货信息</p>
            </div> -->
            <div class="tool-p" v-html="detailsList.content"> </div>
            <div class="anniu-wrap">
                <div class="anniu-p">表单信息：</div>
                <div class="anniu">
                    <div class="button" @click="goTo('supply_join')">
                        <img src="../../../assets/images/button.png" alt="">
                        <p>发布供求易货信息</p>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getdetails } from "../../../api/index"
    import {mapState, mapMutations, mapActions} from 'vuex' 
    export default {
        name:'state_list',
        data() {
            return {
                id:'',  
                detailsList:[]              
            }
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        created() {
            this.id=this.$route.query.id
            this.getdetails()
        },
        methods: {    
            // 跳转页面
            goTo(url,query) {
                if ( !this.isLogin) {                    
                    this.$confirm('请您先登录注册会员','温馨提示', {
                        confirmButtonText: '去登录',
                        }).then(() => {
                            this.$router.push({
                                path:'/login'
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '请登录后再操作'
                            });
                    });
                    return;
                }
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            getdetails(){
                getdetails({
                    id:this.id
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.detailsList=res.data
                    }

                })
            }                  
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{margin: 35px auto;padding-left: 15px;box-sizing: border-box;}
    .conter{
        margin: auto;margin-bottom:140px;padding:70px 100px;box-sizing: border-box;background:#fff;
        .text{font-size: 28px;color: #555555;text-align: center;}
        .tool-p{
            color: #666666;
            font-size: 16px;
            line-height: 32px;
            margin-top: 20px;
            img{width: 100%;}
        }
        .anniu-wrap{
            padding-top: 40px;
            .anniu-p{
                color:#555555;
                font-size: 22px;
            }
            .anniu{
                display: flex;flex-wrap: wrap;
                .button{
                    width: 160px;height: 43px;position: relative;cursor: pointer;margin-right:2%;margin-top:30px;
                    img{width: 100%;height: 100%;}
                    p{
                        width: 100%;height: 100%;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 18px;
                        display: flex;align-items:center;justify-content: center;
                        i{font-size: 25px;padding-top: 3px;}
                    }
                    &:nth-child(6n+0){margin-right: 0;}
                } 

            }
        }

    }

</style>